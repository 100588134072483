@import "../../../../assets/scss/variable.scss";

.btn {
  &.custombtn {
    background: $BG_BLUE;
    border: 1px solid transparent !important;
    font-size: $FONT_16 !important;
    font-weight: normal;
    border-radius: 45px !important;
    padding: 6px 20px !important;
    color: $TEXT_WHITE !important;
    box-shadow: none;
    min-height: 48px;
    transition: none !important;
    cursor: pointer;
    min-width: 200px;
    font-family: 'Open Sans',  sans-serif;

    &:disabled {
      opacity: 1 !important;
      cursor: no-drop;
      background: $BTN_DISABLED;
      pointer-events: all;
      color: $BTN_TEXT_DISABLED !important;
      border: 1px solid transparent !important;
    }

    &:not(:disabled) {
      box-shadow: none;
      background: $BG_BLUE;
    }

    &:not(:disabled):not(.disabled):active {
      background: $BG_BLUE;
    }

    &:not(.disabled):focus {
      box-shadow: none !important;
      background: $BG_BLUE;
    }

    &.btn-loading {
      opacity: 1 !important;
      cursor: default;
    }
  }
}