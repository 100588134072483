//BG COLORS
$BG_WHITE: #ffffff;
$BODY_BG: #f1f7ff;
$BTN_DISABLED: #e5e5e5;
$BG_RED: #dd0000;
$BG_GREEN: #6dd686;
$BG_BLUE: #5034ff;
$BG_BLUEDARK: #6955fe;
$BG_BORDER: #666666;
$BG_PROFILE: #bed3f4;
$BG_YELLLOW: #ffc84a;

// Text Colors
$TEXT_WHITE: #ffffff;
$TEXT_BLACK: #000000;
$TEXT_BLACKLIGHT: #333333;
$TEXT_PLACEHOLDER: #cccccc;
$BTN_TEXT_DISABLED: #999999;
$TEXT_RED: #dd0000;
$TEXT_GREEN: #6dd686;
$TEXT_BLUE: #5034ff;
$TEXT_YELLLOW: #ffc84a;

// Font Size
$FONT_10: 10px;
$FONT_12: 12px;
$FONT_14: 14px;
$FONT_16: 16px;
$FONT_18: 18px;
$FONT_20: 20px;
$FONT_22: 22px;
$FONT_24: 24px;
$FONT_26: 26px;
$FONT_28: 28px;
$FONT_30: 30px;
$FONT_32: 32px;
