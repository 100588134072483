@import "../../../../assets/scss/variable.scss";

.modal {
  .modal-header {
    padding: 0;
    border: none;
  }

  .modal-dialog {
    width: 350px;
    max-width: 98%;

    .modal-content {
      width: 100%;
      margin: 0 auto;
      border: 0;
      border-radius: 16px;
      overflow: hidden;
      box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -webkit-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -moz-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -ms-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      -o-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
      position: relative;
    }
  }
}
