@import "../../assets/scss/variable.scss";

.pageNotFoundMain {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .whiteBox {
    width: 550px;
    max-width: 98%;
    margin-left: auto;
    margin-right: auto;
    background-color: $BG_WHITE;
    border-radius: 24px;
    padding: 70px;
    box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
    text-align: left;

    h1 {
      margin: 0;
      font-size: 70px;
      color: $TEXT_BLUE;
      font-weight: 600;
    }

    p {
      margin: 30px 0 0 0;
      font-size: 34px;
      line-height: 34px;
      color: $TEXT_BLACKLIGHT;
    }

    h6 {
      margin: 36px 0;
      font-size: $FONT_24;
      color: $TEXT_BLACKLIGHT;
    }
  }
}
