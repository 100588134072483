@import "./assets/scss/variable.scss";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600&display=swap");

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-credentials-auto-fill-button {
  background-color: white;
}

body {
  position: relative;
  background-color: $BODY_BG !important;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif !important;
  font-size: $FONT_14;
}

@font-face {
  font-family: "cloud9";
  src: url("./assets/fonts/cloud9.eot?4s1s1x");
  src: url("./assets/fonts/cloud9.eot?4s1s1x#iefix") format("embedded-opentype"),
    url("./assets/fonts/cloud9.ttf?4s1s1x") format("truetype"),
    url("./assets/fonts/cloud9.woff?4s1s1x") format("woff"),
    url("./assets/fonts/cloud9.svg?4s1s1x#cloud9") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "cloud9" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-User2:before {
  content: "\e900";
}

.icon-User3:before {
  content: "\e901";
}

.icon-Activity1:before {
  content: "\e902";
}

.icon-Add-User1:before {
  content: "\e903";
}

.icon-Arrow---Down-21:before {
  content: "\e904";
}

.icon-Arrow---Down-31:before {
  content: "\e905";
}

.icon-Arrow---Down-Circle1:before {
  content: "\e906";
}

.icon-Arrow---Down-Square1:before {
  content: "\e907";
}

.icon-Arrow---Down1:before {
  content: "\e908";
}

.icon-Arrow---Left-21:before {
  content: "\e909";
}

.icon-Arrow---Left-31:before {
  content: "\e90a";
}

.icon-Arrow---Left-Circle1:before {
  content: "\e90b";
}

.icon-Arrow---Left-Square1:before {
  content: "\e90c";
}

.icon-Arrow---Left1:before {
  content: "\e90d";
}

.icon-Arrow---Right-21:before {
  content: "\e90e";
}

.icon-Arrow---Right-31:before {
  content: "\e90f";
}

.icon-Arrow---Right-Circle1:before {
  content: "\e910";
}

.icon-Arrow---Right-Square1:before {
  content: "\e911";
}

.icon-Arrow---Right1:before {
  content: "\e912";
}

.icon-Arrow---Up-21:before {
  content: "\e913";
}

.icon-Arrow---Up-31:before {
  content: "\e914";
}

.icon-Arrow---Up-Circle1:before {
  content: "\e915";
}

.icon-Arrow---Up-Square1:before {
  content: "\e916";
}

.icon-Arrow---Up1:before {
  content: "\e917";
}

.icon-Bag-21:before {
  content: "\e918";
}

.icon-Bag1:before {
  content: "\e919";
}

.icon-Bookmark1:before {
  content: "\e91a";
}

.icon-Buy1:before {
  content: "\e91b";
}

.icon-Calendar1:before {
  content: "\e91c";
}

.icon-Call-Missed1:before {
  content: "\e91d";
}

.icon-Call-Silent1:before {
  content: "\e91e";
}

.icon-Call1:before {
  content: "\e91f";
}

.icon-Calling1:before {
  content: "\e920";
}

.icon-Camera1:before {
  content: "\e921";
}

.icon-Category1:before {
  content: "\e922";
}

.icon-Chart1:before {
  content: "\e923";
}

.icon-Chat1:before {
  content: "\e924";
}

.icon-Close-Square1:before {
  content: "\e925";
}

.icon-Danger1:before {
  content: "\e926";
}

.icon-Delete1:before {
  content: "\e927";
}

.icon-Discount1:before {
  content: "\e928";
}

.icon-Discovery1:before {
  content: "\e929";
}

.icon-Document1:before {
  content: "\e92a";
}

.icon-Download1:before {
  content: "\e92b";
}

.icon-Edit-Square1:before {
  content: "\e92c";
}

.icon-Edit1:before {
  content: "\e92d";
}

.icon-Filter-21:before {
  content: "\e92e";
}

.icon-Filter1:before {
  content: "\e92f";
}

.icon-Folder1:before {
  content: "\e930";
}

.icon-Game1:before {
  content: "\e931";
}

.icon-Graph1:before {
  content: "\e932";
}

.icon-Heart1:before {
  content: "\e933";
}

.icon-Hide1:before {
  content: "\e934";
}

.icon-Home1:before {
  content: "\e935";
}

.icon-Image-21:before {
  content: "\e936";
}

.icon-Image1:before {
  content: "\e937";
}

.icon-Info-Circle1:before {
  content: "\e938";
}

.icon-Info-Square1:before {
  content: "\e939";
}

.icon-Location1:before {
  content: "\e93a";
}

.icon-Lock1:before {
  content: "\e93b";
}

.icon-Login1:before {
  content: "\e93c";
}

.icon-Logout1:before {
  content: "\e93d";
}

.icon-Message1:before {
  content: "\e93e";
}

.icon-More-Circle1:before {
  content: "\e93f";
}

.icon-More-Square1:before {
  content: "\e940";
}

.icon-Notification1:before {
  content: "\e941";
}

.icon-Paper-Download1:before {
  content: "\e942";
}

.icon-Paper-Fail1:before {
  content: "\e943";
}

.icon-Paper-Negative1:before {
  content: "\e944";
}

.icon-Paper-Plus1:before {
  content: "\e945";
}

.icon-Paper-Upload1:before {
  content: "\e946";
}

.icon-Paper1:before {
  content: "\e947";
}

.icon-Password1:before {
  content: "\e948";
}

.icon-Play1:before {
  content: "\e949";
}

.icon-Plus1:before {
  content: "\e94a";
}

.icon-Profile1:before {
  content: "\e94b";
}

.icon-Scan1:before {
  content: "\e94c";
}

.icon-Search1:before {
  content: "\e94d";
}

.icon-Send1:before {
  content: "\e94e";
}

.icon-Setting1:before {
  content: "\e94f";
}

.icon-Shield-Done1:before {
  content: "\e950";
}

.icon-Shield-Fail1:before {
  content: "\e951";
}

.icon-Show1:before {
  content: "\e952";
}

.icon-Star1:before {
  content: "\e953";
}

.icon-Swap1:before {
  content: "\e954";
}

.icon-Tick-Square1:before {
  content: "\e955";
}

.icon-Ticket-Star1:before {
  content: "\e956";
}

.icon-Ticket1:before {
  content: "\e957";
}

.icon-Time-Circle1:before {
  content: "\e958";
}

.icon-Time-Square1:before {
  content: "\e959";
}

.icon-Unlock1:before {
  content: "\e95a";
}

.icon-Upload1:before {
  content: "\e95b";
}

.icon-Video1:before {
  content: "\e95c";
}

.icon-Voice-21:before {
  content: "\e95d";
}

.icon-Voice1:before {
  content: "\e95e";
}

.icon-Volume-Down1:before {
  content: "\e95f";
}

.icon-Volume-Off1:before {
  content: "\e960";
}

.icon-Volume-Up1:before {
  content: "\e961";
}

.icon-Wallet1:before {
  content: "\e962";
}

.icon-Work1:before {
  content: "\e963";
}

.icon-User:before {
  content: "\e964";
}

.icon-User1:before {
  content: "\e965";
}

.icon-Activity:before {
  content: "\e966";
}

.icon-Add-User:before {
  content: "\e967";
}

.icon-Arrow---Down-2:before {
  content: "\e968";
}

.icon-Arrow---Down-3:before {
  content: "\e969";
}

.icon-Arrow---Down-Circle:before {
  content: "\e96a";
}

.icon-Arrow---Down-Square:before {
  content: "\e96b";
}

.icon-Arrow---Down:before {
  content: "\e96c";
}

.icon-Arrow---Left-2:before {
  content: "\e96d";
}

.icon-Arrow---Left-3:before {
  content: "\e96e";
}

.icon-Arrow---Left-Circle:before {
  content: "\e96f";
}

.icon-Arrow---Left-Square:before {
  content: "\e970";
}

.icon-Arrow---Left:before {
  content: "\e971";
}

.icon-Arrow---Right-2:before {
  content: "\e972";
}

.icon-Arrow---Right-3:before {
  content: "\e973";
}

.icon-Arrow---Right-Circle:before {
  content: "\e974";
}

.icon-Arrow---Right-Square:before {
  content: "\e975";
}

.icon-Arrow---Right:before {
  content: "\e976";
}

.icon-Arrow---Up-2:before {
  content: "\e977";
}

.icon-Arrow---Up-3:before {
  content: "\e978";
}

.icon-Arrow---Up-Circle:before {
  content: "\e979";
}

.icon-Arrow---Up-Square:before {
  content: "\e97a";
}

.icon-Arrow---Up:before {
  content: "\e97b";
}

.icon-Bag-2:before {
  content: "\e97c";
}

.icon-Bag:before {
  content: "\e97d";
}

.icon-Bookmark:before {
  content: "\e97e";
}

.icon-Buy:before {
  content: "\e97f";
}

.icon-Calendar:before {
  content: "\e980";
}

.icon-Call-Missed:before {
  content: "\e981";
}

.icon-Call-Silent:before {
  content: "\e982";
}

.icon-Call:before {
  content: "\e983";
}

.icon-Calling:before {
  content: "\e984";
}

.icon-Camera:before {
  content: "\e985";
}

.icon-Category:before {
  content: "\e986";
}

.icon-Chart:before {
  content: "\e987";
}

.icon-Chat:before {
  content: "\e988";
}

.icon-Close-Square:before {
  content: "\e989";
}

.icon-Danger:before {
  content: "\e98a";
}

.icon-Delete:before {
  content: "\e98b";
}

.icon-Discount:before {
  content: "\e98c";
}

.icon-Discovery:before {
  content: "\e98d";
}

.icon-Document:before {
  content: "\e98e";
}

.icon-Download:before {
  content: "\e98f";
}

.icon-Edit-Square:before {
  content: "\e990";
}

.icon-Edit:before {
  content: "\e991";
}

.icon-Filter-2:before {
  content: "\e992";
}

.icon-Filter:before {
  content: "\e993";
}

.icon-Folder:before {
  content: "\e994";
}

.icon-Game:before {
  content: "\e995";
}

.icon-Graph:before {
  content: "\e996";
}

.icon-Heart:before {
  content: "\e997";
}

.icon-Hide:before {
  content: "\e998";
}

.icon-Home:before {
  content: "\e999";
}

.icon-Image-2:before {
  content: "\e99a";
}

.icon-Image:before {
  content: "\e99b";
}

.icon-Info-Circle:before {
  content: "\e99c";
}

.icon-Info-Square:before {
  content: "\e99d";
}

.icon-Location:before {
  content: "\e99e";
}

.icon-Lock:before {
  content: "\e99f";
}

.icon-Login:before {
  content: "\e9a0";
}

.icon-Logout:before {
  content: "\e9a1";
}

.icon-Message:before {
  content: "\e9a2";
}

.icon-More-Circle:before {
  content: "\e9a3";
}

.icon-More-Square:before {
  content: "\e9a4";
}

.icon-Notification:before {
  content: "\e9a5";
}

.icon-Paper-Download:before {
  content: "\e9a6";
}

.icon-Paper-Fail:before {
  content: "\e9a7";
}

.icon-Paper-Negative:before {
  content: "\e9a8";
}

.icon-Paper-Plus:before {
  content: "\e9a9";
}

.icon-Paper-Upload:before {
  content: "\e9aa";
}

.icon-Paper:before {
  content: "\e9ab";
}

.icon-Password:before {
  content: "\e9ac";
}

.icon-Play:before {
  content: "\e9ad";
}

.icon-Plus:before {
  content: "\e9ae";
}

.icon-Profile:before {
  content: "\e9af";
}

.icon-Scan:before {
  content: "\e9b0";
}

.icon-Search:before {
  content: "\e9b1";
}

.icon-Send:before {
  content: "\e9b2";
}

.icon-Setting:before {
  content: "\e9b3";
}

.icon-Shield-Done:before {
  content: "\e9b4";
}

.icon-Shield-Fail:before {
  content: "\e9b5";
}

.icon-Show:before {
  content: "\e9b6";
}

.icon-Star:before {
  content: "\e9b7";
}

.icon-Swap:before {
  content: "\e9b8";
}

.icon-Tick-Square:before {
  content: "\e9b9";
}

.icon-Ticket-Star:before {
  content: "\e9ba";
}

.icon-Ticket:before {
  content: "\e9bb";
}

.icon-Time-Circle:before {
  content: "\e9bc";
}

.icon-Time-Square:before {
  content: "\e9bd";
}

.icon-Unlock:before {
  content: "\e9be";
}

.icon-Upload:before {
  content: "\e9bf";
}

.icon-Video:before {
  content: "\e9c0";
}

.icon-Voice-2:before {
  content: "\e9c1";
}

.icon-Voice:before {
  content: "\e9c2";
}

.icon-Volume-Down:before {
  content: "\e9c3";
}

.icon-Volume-Off:before {
  content: "\e9c4";
}

.icon-Volume-Up:before {
  content: "\e9c5";
}

.icon-Wallet:before {
  content: "\e9c6";
}

.icon-Work:before {
  content: "\e9c7";
}

.icon-Tick:before {
  content: "\e9c8";
}

.icon-x:before {
  content: "\e9d2";
}

.table-scroll {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
  scrollbar-color: #e4e4e4 transparent;
  scrollbar-width: thin;
}

.table-scroll table {
  width: 100%;
  min-width: 980px;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0 8px;
}

.table-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
}

.table-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.table-scroll th,
.table-scroll td {
  padding: 0 12px;
  border: 0;
  height: 64px;
  vertical-align: middle;
  font-weight: normal;
  font-size: $FONT_14;
}

.table-scroll thead th {
  background-color: #f6f5ff;
  color: $TEXT_BLACK;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0;
  text-align: left;
  z-index: 9;

}

.table-scroll thead th .icon {
  font-size: $FONT_16;
  cursor: pointer;
  margin-left: 8px;
  color: $TEXT_BLACKLIGHT;
}

.table-scroll thead tr {
  -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
}

.table-scroll tbody tr {
  background-color: $BG_WHITE;
  -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.08);

  &:last-child {
    overflow: hidden;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    td {
      &:first-child {
        border-bottom-left-radius: 16px;
      }

      &:last-child {
        border-bottom-right-radius: 16px;
      }
    }
  }
}

.table-scroll tbody tr.disabled td {
  opacity: 0.6;
}

.table-scroll tbody tr.disabled td:nth-child(1),
.table-scroll tbody tr.disabled td:nth-child(2),
.table-scroll tbody tr.disabled td:nth-child(3),
.table-scroll tbody tr.disabled td:nth-child(4),
.table-scroll tbody tr.disabled td:nth-child(5),
.table-scroll tbody tr.disabled td .actionInline .deleteBack {
  pointer-events: none;
}

.table-scroll tbody td {
  cursor: pointer;
  background-color: $BG_WHITE;

  &:nth-child(4) {
    cursor: default;
  }
}

thead th:first-child,
thead th:last-child {
  z-index: 99;
}

.logoutModal {
  .modal-header {
    text-align: right;
    padding: 12px;
    display: block;

    i {
      cursor: pointer;
      color: $TEXT_BLACK;
      font-size: $FONT_14;
    }
  }

  h6 {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_22;
    color: $TEXT_RED;
    font-weight: 600;
  }

  p {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_16;
    color: $TEXT_BLACK;
    font-weight: 600;
  }

  .btnInline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 0 0;
    padding: 12px;

    .custombtn {
      width: 48%;
      min-height: 40px;
      min-width: inherit;

      &:first-child {
        background-color: $BG_RED;

        &:focus {
          background-color: $BG_RED !important;
        }
      }

      &:last-child {
        background-color: transparent;
        border: 2px solid $BG_BORDER !important;
        color: $BG_BORDER !important;

        &:focus {
          background-color: transparent !important;
        }
      }
    }
  }
}

.deleteUserModal {
  .modal-header {
    text-align: right;
    padding: 12px;
    display: block;

    i {
      cursor: pointer;
      color: $TEXT_BLACK;
      font-size: $FONT_14;
    }
  }

  h6 {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_22;
    color: $TEXT_RED;
    font-weight: 600;
  }

  p {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_16;
    color: $TEXT_BLACK;
    font-weight: 600;
  }

  .btnInline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 0 0;
    padding: 12px;

    .custombtn {
      width: 48%;
      min-height: 40px;
      min-width: inherit;

      &:first-child {
        background-color: $BG_RED;

        &:focus {
          background-color: $BG_RED !important;
        }
      }

      &:last-child {
        background-color: transparent;
        border: 2px solid $BG_BORDER !important;
        color: $BG_BORDER !important;

        &:focus {
          background-color: transparent !important;
        }
      }
    }
  }
}

.userStatusModal {
  .modal-header {
    text-align: right;
    padding: 12px;
    display: block;

    i {
      cursor: pointer;
      color: $TEXT_BLACK;
      font-size: $FONT_14;
    }
  }

  .userStatusDe {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_22;
    color: $TEXT_YELLLOW;
    font-weight: 600;
  }

  .userStatusAc {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_22;
    color: $TEXT_YELLLOW;
    font-weight: 600;
  }

  p {
    margin: 14px 0 0 0;
    text-align: center;
    font-size: $FONT_16;
    color: $TEXT_BLACK;
    font-weight: 600;
    width: 220px;
    margin-left: auto;
    margin-right: auto;
  }

  .btnInline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0 0 0;
    padding: 12px;

    .custombtn {
      width: 48%;
      min-height: 40px;
      min-width: inherit;

      &:first-child {
        color: $TEXT_BLACKLIGHT !important;
        background-color: $BG_YELLLOW;

        &:focus {
          background-color: $BG_YELLLOW !important;
          color: $TEXT_BLACKLIGHT;
        }
      }

      &:last-child {
        background-color: transparent;
        border: 2px solid $BG_BORDER !important;
        color: $BG_BORDER !important;

        &:focus {
          background-color: transparent !important;
        }
      }
    }
  }
}

.profileModal {
  .modal-dialog {
    width: 1000px;
    max-width: 98%;

    .modal-content {
      .modal-header {
        text-align: left;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        background-color: $BG_BLUE;
        padding: 10px 16px;
        box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
          inset 0px -12px 24px rgba(91, 160, 237, 0.5),
          inset 0px 12px 24px rgba(91, 160, 237, 0.5);
        -webkit-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
          inset 0px -12px 24px rgba(91, 160, 237, 0.5),
          inset 0px 12px 24px rgba(91, 160, 237, 0.5);
        -moz-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
          inset 0px -12px 24px rgba(91, 160, 237, 0.5),
          inset 0px 12px 24px rgba(91, 160, 237, 0.5);
        -ms-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
          inset 0px -12px 24px rgba(91, 160, 237, 0.5),
          inset 0px 12px 24px rgba(91, 160, 237, 0.5);
        -o-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
          inset 0px -12px 24px rgba(91, 160, 237, 0.5),
          inset 0px 12px 24px rgba(91, 160, 237, 0.5);
        backdrop-filter: blur(230px);
        -webkit-backdrop-filter: blur(230px);

        p {
          margin: 0;
          font-weight: 600;
          font-size: $FONT_18;
          color: $TEXT_WHITE;
          margin-left: 18px;
        }

        .icon-x {
          color: $TEXT_WHITE;
          font-size: $FONT_14;
          padding: 2px;
          opacity: 1;
          cursor: pointer;

          &:focus {
            box-shadow: none;
          }
        }
      }

      .modalMain {
        position: relative;
        padding: 24px 36px;
        width: 100%;
        float: left;

        .userTopPart {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          float: left;
          margin-bottom: 36px;

          .left {
            position: relative;
            float: left;

            ul {
              margin: 0;
              padding: 0;
              width: 70px;
              float: left;

              li {
                margin-bottom: 10px;
                list-style: none;

                &:last-child {
                  margin-bottom: 0;
                }

                img {
                  cursor: pointer;
                  width: 70px;
                  height: 70px;
                  display: block;
                  object-fit: cover;
                }
              }

              .avtar {
                margin-bottom: 10px;
                width: 70px;
                height: 70px;
                background-color: $BODY_BG;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                  font-size: $FONT_24;
                  color: $BG_PROFILE;
                }

                &:last-child {
                  margin-bottom: 0;
                }
              }
            }

            .imageView {
              margin-left: 10px;
              float: left;

              img {
                width: 310px;
                height: 310px;
                object-fit: cover;
                display: block;
              }

              .avtar {
                background-color: $BODY_BG;
                width: 310px;
                height: 310px;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                  font-size: $FONT_32;
                  color: $BG_PROFILE;
                }
              }
            }
          }

          .center {
            float: left;
            padding-left: 16px;

            .username {
              font-size: $FONT_32;
              color: $TEXT_BLUE;
              margin: 0;
              width: 340px;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 100%;
            }

            .email {
              font-size: $FONT_14;
              color: $TEXT_BLACK;
              margin: 2px 0;
            }

            .reported {
              font-size: $FONT_14;
              color: $TEXT_RED;
              margin: 0;
            }
          }

          .right {
            float: right;
            text-align: right;

            .contentInline {
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .title {
                font-weight: 600;
                font-size: $FONT_18;
                color: $TEXT_BLUE;
                margin: 0;
              }

              .backColor {
                display: inline-block;
                background-color: #f7f8f9;
                border-radius: 8px;
                padding: 8px;
                margin-left: 10px;

                .icon-Delete {
                  font-size: $FONT_18;
                  color: $TEXT_RED;
                }
              }

              .deleteBack {
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: #f7f8f9;
                border-radius: 8px;
                margin-left: 10px;
                cursor: pointer;

                .icon-Delete {
                  font-size: $FONT_24;
                  color: $TEXT_RED;
                }
              }
            }
          }
        }

        .userContent {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;
          float: left;

          .userLeft {
            display: flex;
            align-items: flex-start;
            flex-wrap: wrap;
            width: 49%;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 14px;

            .titlePart {
              width: 100%;
              border-top: 1px solid rgba(0, 0, 0, 0.15);
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              padding: 8px 0;

              p {
                margin: 0;
                font-size: $FONT_18;
                font-weight: 600;
                color: $TEXT_BLUE;
              }
            }

            ul {
              margin: 0;
              padding: 0;
              width: 100%;

              li {
                list-style: none;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin-top: 14px;

                span {
                  &:first-child {
                    font-weight: 600;
                    font-size: $FONT_14;
                    color: $TEXT_BLACKLIGHT;
                  }

                  &:last-child {
                    padding-left: 10px;
                    font-weight: 400;
                    font-size: $FONT_14;
                    color: $TEXT_BLACKLIGHT;
                    text-transform: capitalize;
                  }
                }
              }
            }
          }

          .userRight {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            width: 49%;
            position: relative;
            border-bottom: 1px solid rgba(0, 0, 0, 0.15);
            padding-bottom: 14px;

            .titlePart {
              border-top: 1px solid rgba(0, 0, 0, 0.15);
              border-bottom: 1px solid rgba(0, 0, 0, 0.15);
              padding: 8px 0;
              width: 100%;
              min-height: 45px;

              p {
                margin: 0;
                font-size: $FONT_18;
                font-weight: 600;
                color: $TEXT_BLUE;
              }
            }

            ul {
              margin: 0;
              padding: 0;
              width: 100%;

              li {
                list-style: none;
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 14px;

                span {
                  &:first-child {
                    font-weight: 600;
                    font-size: $FONT_14;
                    color: $TEXT_BLACKLIGHT;
                  }

                  &:last-child {
                    font-weight: 400;
                    font-size: $FONT_14;
                    color: $TEXT_BLACKLIGHT;
                    text-transform: capitalize;
                  }
                }

                &:last-child {
                  display: block;

                  span {
                    display: block;
                    word-break: break-all;

                    &:first-child {
                      margin-bottom: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.abuseReporterModal {
  .modal-dialog {
    width: 700px;
    max-width: 98%;

    .modal-content {
      .modal-header {
        text-align: right;
        padding: 12px;
        display: block;

        i {
          cursor: pointer;
          color: $TEXT_BLACK;
          font-size: $FONT_14;
        }
      }

      h6 {
        margin: 24px 0 0 0;
        text-align: center;
        font-size: $FONT_22;
        color: $TEXT_RED;
        font-weight: 600;
      }

      .table-content {
        position: relative;
        padding: 24px 14px;

        &::after {
          position: absolute;
          content: "";
          right: 0;
          bottom: 0;
          width: 8px;
          height: 8px;
          background-color: $BODY_BG;
          z-index: 9;
        }

        .table-scroll {
          max-height: 300px !important;
          padding: 0 10px 0 10px;

          table {
            min-width: inherit;

            thead {
              th {
                cursor: default;

                .alignCenter {
                  display: flex;
                  align-items: center;
                }

                .preWrap {
                  white-space: pre-wrap;
                }
              }
            }

            tbody {
              tr {
                td {
                  cursor: default;
                  background-color: transparent;

                  .no-record {
                    padding: 50px 0;
                    text-align: center;
                    width: 100%;
                    margin: 0;
                    background-color: $BG_WHITE;

                    div {
                      width: 340px;
                      max-width: 100%;
                      margin: 0 auto;
                    }

                    p {
                      margin: 20px 0;
                      font-size: $FONT_20;
                      color: $TEXT_BLACKLIGHT;
                    }
                  }

                  .profilename {
                    display: flex;
                    align-items: "center";
                    justify-content: flex-start;

                    img {
                      width: 40px;
                      height: 40px;
                      border-radius: 20px;
                      object-fit: cover;
                      margin-right: 10px;
                    }
                  }
                }

                &:last-child {
                  overflow: hidden;
                  border-bottom-left-radius: 16px;
                  border-bottom-right-radius: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}