@import "../../../../assets/scss/variable.scss";

.toast {
  position: fixed;
  top: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;
  text-align: center;
  flex-basis: inherit !important;
  margin: 0 auto !important;
  border: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
  padding: 16px;
  display: none;
  width: inherit !important;

  .tostcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    border-radius: 16px;
    padding: 16px 20px;
    text-align: center;
    margin: 0 auto;
    background-color: $BG_WHITE;
    box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
    -webkit-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
    -moz-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
    -ms-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
    -o-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);

    position: relative;
    overflow: hidden;

    p {
      font-weight: 600;
      text-align: center;
      margin: 6px 0 0 0;
      font-size: $FONT_16;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      color: $TEXT_BLACK;
      word-break: break-word;
    }

    i {
      font-size: $FONT_24;
    }

    .icon-Tick-Square {
      color: $TEXT_BLUE;
    }

    .icon-Close-Square {
      color: $TEXT_RED;
    }

  }

  &.show {
    display: block;
  }

}
