@import "../../assets/scss/variable.scss";

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  background-color: $BG_BLUE;
  padding: 15px 0;
  z-index: 9;
  box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
    inset 0px -12px 24px rgba(91, 160, 237, 0.5),
    inset 0px 12px 24px rgba(91, 160, 237, 0.5);
  -webkit-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
    inset 0px -12px 24px rgba(91, 160, 237, 0.5),
    inset 0px 12px 24px rgba(91, 160, 237, 0.5);
  -moz-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
    inset 0px -12px 24px rgba(91, 160, 237, 0.5),
    inset 0px 12px 24px rgba(91, 160, 237, 0.5);
  -ms-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
    inset 0px -12px 24px rgba(91, 160, 237, 0.5),
    inset 0px 12px 24px rgba(91, 160, 237, 0.5);
  -o-box-shadow: 0px 5px 2px rgba(88, 167, 255, 0.15),
    inset 0px -12px 24px rgba(91, 160, 237, 0.5),
    inset 0px 12px 24px rgba(91, 160, 237, 0.5);
  backdrop-filter: blur(230px);
  -webkit-backdrop-filter: blur(230px);

  .headerCenter {
    width: 1592px;
    max-width: 100%;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .logoarea {
      img {
        width: 130px;
        height: auto;
        display: block;
      }
    }

    .profileMain {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .dropdown {
        margin-right: 10px;
        position: relative;

        .dropdown-toggle {
          padding: 0;
          border: 0;
          border-radius: 0;
          font-weight: normal;
          font-size: inherit;
          background-color: transparent !important;
          text-align: right;
          font-family: "Open Sans", sans-serif;
          position: relative;
          padding-right: 24px;

          i {
            position: absolute;
            right: 0;
            top: 50%;
            color: $TEXT_WHITE;
            font-size: $FONT_16;
            margin-top: -8px;
          }

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &::after {
            display: none;
          }

          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 250px;
            display: block;
            color: $TEXT_WHITE;

            &:first-child {
              font-size: $FONT_14;
            }

            &:last-child {
              font-size: $FONT_16;
            }

          }
        }

        .dropdown-menu {
          top: 100%;
          padding: 8px 0;
          border-radius: 16px;
          border: 0;
          right: 0 !important;
          left: auto !important;
          background-color: $BG_WHITE;
          box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
          -webkit-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
          -moz-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
          -ms-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);
          -o-box-shadow: 4px 4px 20px rgba(228, 216, 222, 0.43);

          .dropdown-item {
            padding: 4px 16px;
            margin: 8px 0;
            color: $TEXT_BLACK;
            font-size: $FONT_14;
            font-family: "Open Sans", sans-serif;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            i {
              margin-right: 10px;
              color: $TEXT_BLUE;
              font-size: $FONT_22;
            }

            &:last-child {
              color: $TEXT_RED;

              i {
                color: $TEXT_RED;
              }
            }

            &:hover {
              background-color: transparent;
            }
          }
        }
      }

      .imgArea {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background-color: $BG_WHITE;
        border: 4px solid $BG_BLUEDARK;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        color: $TEXT_BLUE;
        font-size: $FONT_20;
        text-transform: uppercase;

        img {
          width: 48px;
          height: 48px;
          border-radius: 24px;
          object-fit: contain;
        }
      }
    }
  }
}